<template>
	<div class="bets">
		<div class="bets__category" v-for="(category, index) in menu" :key="index">
			<div @click="getSubcategories(index, category.id)">
				<h3>{{ category.nombre }}</h3>
			</div>

			<Subcategory
				v-for="(subcategory, sIndex) in filteredSubcategories(category.id)" 
				:key="sIndex"
				:subcategory="subcategory"
				:open="category.open"
			/>

		</div>
		<div class="bets__detail">
			<div class="bets__detail__content" :class="{'showed': bets.length > 0}">
				<span class="just-mobile">{{ bets.length }} logro{{bets.length > 1 ? 's' : ''}} a ganar {{ total | formatBalance }}</span>
				<span class="hide-mobile">{{ bets.length }} logro{{bets.length > 1 ? 's' : ''}} , con una ganancia posible de {{ total | formatBalance }}</span>
				<button class="button" @click="setShowDetail(true)">Continuar</button>
			</div>
		</div>
		<Modal :show="show" @close="setShowDetail(false)" className="confirm">
			<template v-slot:title>
				<h3>Detalle de la Apuesta</h3>
			</template>
			<template v-slot:form>
				<Confirm @close="setShowDetail(false)"/>
			</template>
		</Modal>
	</div>
</template>

<script>
	import Subcategory from './SubCategory.vue'
	import Modal from './Modal.vue'
	import Confirm from './Confirm.vue'
	export default {
		name: 'Bets',
		components: {
			Subcategory,
			Modal,
			Confirm,
		},
		data() {
			return {
				menu: [],
			}
		},
		created() {
			this.$store.dispatch('getCategories')
				.then(() => {
					this.menu = this.$store.getters.categories
				})
		},
		computed: {
			bets() {
				return this.$store.getters.bets
			},
			total() {
				return this.$store.getters.total
			},
			show() {
				return this.$store.getters.showDetail				
			}
		},
		methods: {
			getSubcategories(index, categoryId) {
				if (this.menu[index].open === true) {
					this.menu[index].open = false
					this.$forceUpdate()
				} else if(this.filteredSubcategories(categoryId).length === 0) {
					this.$store.dispatch('getSubcategories', categoryId)
						.then(() => {
							this.menu[index].open = true
							this.$forceUpdate()
						})
				} else {
					this.menu[index].open = true
					this.$forceUpdate()
				}
			},
			filteredSubcategories(categoryId) {
				if (!this.$store.getters.subcategories) {
					return []
				}

				return this.$store.getters.subcategories.filter(subcategory => subcategory.id_menu == categoryId)
			},
			setShowDetail(payload) {
				this.$store.commit('setShowDetail', payload)
			},
		},
		watch: {
			bets: {
				handler(val){
					if(val) {
						this.$store.commit('setTotal')
					}
				},
				deep: true
			}
		}
	}
</script>

<style lang="scss" scoped>
//SCSS File
</style>
