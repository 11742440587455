<template>
	<div class="confirm">
		<div class="confirm__header">
			<div  class="confirm__header__type">
				<TheToggleButton :width="190" :height="50" />
			</div>
			<div class="confirm__header__button">
				<button @click="storeBets" class="button medium">Jugar</button>
			</div>
		</div>
		<div class="confirm__body">
			<div class="confirm__body__top">
				<input v-if="betType" type="number" v-model="amount">
				<span>A ganar: {{ total | formatBalance}}</span>
			</div>
			<p v-if="hasBalance" class="error">SALDO INSUFICIENTE</p>
			<p v-if="showamountError" class="error">Monto debe ser mayor a 0</p>
			
				<transition-group tag="div" class="confirm__body__detail" name="bounce">			
				<div class="confirm__body__detail__item" :class="{ compound: betType }" v-for="bet in bets" :key="bet.id">
					<svg @click="toggleBet(bet)" data-v-701ac82d="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path data-v-701ac82d="" d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg>
					<span class="detail" v-html="detail(bet)"></span>
					<template v-if="!betType">
						<input type="number" v-model="bet.monto" @input="updateAmount">
						<span>{{ directSum(bet) | formatBalance}}</span>
					</template>
				</div>
				</transition-group>
			
		</div>
		<div class="confirm__footer">
			<span>Monto arriesgado: {{ onBet | formatBalance}}</span>
			<br>
			<button v-if="bonus > 0" class="button bonus" @click="toggleUseBonus">
				<template v-if="!useBonus">
					Usar {{ bonus | formatBalance }} de bono
				</template>
				<template v-else>
					No Usar Bonos
				</template>
			</button>
		</div>
	</div>
</template>
<script>
	import TheToggleButton from '../components/TheToggleButton.vue'
	import {betMixin} from '../mixins/betMixin'
	export default {
		name: 'Confirm',
		mixins: [betMixin],
		components: {
			TheToggleButton		
		},
		created(){
			this.sumBets()
		},
        data() {
            return {
                showamountError: false
            }
        },
		computed: {
			betType() {
				return this.$store.getters.betType
			},
			bets() {
				return this.$store.getters.bets
			},
			total() {
				return this.$store.getters.total
			},
			onBet() {
				return this.$store.getters.onBet
			},
			amount: {
				get() {
					return this.$store.getters.amount
				},
				set(value){
					this.$store.commit('setAmount', parseFloat(value))
					this.updateAmount()
				}
			},
			balance() {
				return this.$store.getters.balance
			},
			user() {
				return this.$store.getters.user
			},
			useBonus() {
				return this.$store.getters.useBonus
			},
			bonus() {
				return this.$store.getters.user.bonus
			},
			hasBalance() {
				let balance = this.useBonus ? this.bonus : this.balance
				return this.user.corredor == 0 && this.onBet > balance
			},
		},
		methods: {
			toggleBet(payload) {
				this.$store.dispatch('toggleBet', payload)
				this.updateAmount()

				if(this.bets.length == 0) {
					this.$emit('close')
				}				
			},
			storeBets() {
				if (this.hasBalance) {
					return false
				}

				this.storeBetAmount()

                if (this.onBet < 1) {
                    this.showamountError = true
                    return false
                }

				let data = {
					compuesta : this.betType ? '1' : '0',
					bets : this.bets,
					ganancia : this.total,
					bonus: this.useBonus ? '1' : '0',
				}

				this.$store.dispatch('storeBets', data)
					.then(() => {
						if (this.useBonus && this.onBet <= this.bonus) {
							this.$store.commit('updateBonus', -this.onBet)
							this.$store.commit('toggleUseBonus')
						} else if (this.onBet <= this.balance) { 
							this.$store.commit('updateBalance', -this.onBet)
						}
						
						this.$store.commit('clearGames')
						this.$forceUpdate()

						this.$emit('close')
					})
					.catch(error => {
						console.log(error)
					})
			},
			storeBetAmount() {
				if (this.betType) {
					this.bets[0].monto = this.onBet
				}				
			},
			toggleUseBonus() {
				this.$store.commit('toggleUseBonus')
				this.updateAmount()
			},
			directSum(bet) {
				if (this.useBonus) {
					return bet.monto * bet.probabilidad - bet.monto					
				}
				return bet.monto * bet.probabilidad
			}			
		},
	}
</script>

<style lang="scss" scoped>
	.confirm {
		@extend %bold;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;

		&__header {
			@include flex-container(space-between, center);

			&__type {
				height: 50px;
				width: 190px;
				padding-left: 0;
				padding-top: 0;
				cursor: pointer;

				h3 {
					@extend %bold;
					font-size: 16px;
				}
			}
		}

		&__body {

			&__top {
				@include flex-container(flex-end, center, center);

				span {
					width: 80%;
					text-align: right;
					padding: 20px 0;
				}
			}

			&__detail {

				&__item {
					@include flex-container(space-between, center);
					background-color: $gray2;
					margin: 20px 0;
					padding: 10px 35px 10px 20px;
					text-transform: uppercase;

					&.compound {
						padding: 20px;
					}
				}
			}
		}

		&__footer {
			margin: 40px 0;

			.bonus {
				margin-top: 15px;
			}
		}

		input {
			@include border-radius(20px);
			@extend %bold;
			width: 180px;
			height: 40px;
			text-align: center;	
			font-size: 20px;
			color: $orangeTheme;
		}

		span {
			font-size: 20px;
			color: $orangeTheme;

			&.detail {				
				width: 50%;
			}
		}

		svg {
			@include effect(all, .3, ease-out);
			width: 12px;
			height: 12px;
			position: absolute;
			top: 10%;
			right: .5%;
			cursor: pointer;
			fill: $darkGray;
		}
		
		.error {
			display: block;
			color: $red;
			font-size: 16px;
			margin: 0;
			padding: 0;
			margin-top: 8px;			
		}
	}

	@include BigDesktop {

		.confirm {

			span {

				&.detail {
					width: 70%;
					font-size: 18px;
				}
			}

			input {
				width: 80px;
			}
		}
	}

	@include tabletLandscape {

		.confirm {			

			span {

				&.detail {
					font-size: 17px;
				}
			}
		}
	}

	@include mobile {

		.confirm {

			&__header {
				margin-top: 15px;

				&__type {
					height: 44px;
					width: 120px;
					padding-left: 0;
					padding-top: 0;					
					
					h3 {
						font-size: 11px;
					}
				}
			}

			&__body {

				&__top {
					margin-top: 15px;

					span {
						width: 60%;
					}
				}

				&__detail {

					&__item {
	
						&.compound {
							padding: 10px;
						}

						input {
							margin-top: 5px;
						}
					}
				}
			}
			
			span {
				font-size: 14px;

				&.detail {					
					font-size: 14px;
					width: 100%;
					text-align: center;					
				}
			}

			input {
				height: 35px;
				width: 125px;
				font-size: 14px;
			}

			.error {
				font-size: 14px;			
			}
		}
	}

	@include nomobile {

		.confirm {		

			svg {

				&:hover {
					fill: $orangeTheme;
				}
			}
		}
	}
</style>