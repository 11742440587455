<template>
	<div>
		<toggle-button 
			v-model="checked"
			:color="{checked: '#ea7c0f', unchecked: '#87919e'}"
			:width="width"
			:height="height"			
			:labels="{checked: 'Compuesta', unchecked: 'Directa'}"
			class="hide-mobile"
		/>
		<toggle-button 
			v-model="checked"
			:color="{checked: '#ea7c0f', unchecked: '#87919e'}"
			:width="130"
			:height="44"			
			:labels="{checked: 'Compuesta', unchecked: 'Directa'}"
			class="just-mobile"
		/>
	</div>
</template>

<script>
	import { ToggleButton } from 'vue-js-toggle-button'
	import {betMixin} from '../mixins/betMixin'
	export default {
		name: 'TheToggleutton',
		mixins: [betMixin],		
		components: {
			ToggleButton
		},
		props:{
			width: {
				type: Number,
				default: 420
			},
			height: {
				type: Number,
				default: 120
			},
		},
		computed: {
			checked: {
				get() {
					return this.betType
				},
				set() {
					this.$store.commit('toggleBetType')
					this.updateAmount()
				}
			},
			betType() {
				return this.$store.getters.betType 
			},
		},
		methods: {			
			toggle() {
				this.$store.commit('toggleBetType')
				this.updateAmount()				
			}
		}
	}
</script>

<style lang="scss" scoped>


</style>