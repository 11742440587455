<template>
	<div class="bets__category__subcategory" :class="{ open: open }">
		<!-- subcategories -->
		<div class="bets__category__subcategory__title" @click="getGames">
			<h4>{{ subcategory.nombre }}</h4>
		</div>
		<div class="bets__category__subcategory__content" :class="{expanded: expanded}">
			<Game v-for="game in games" :key="game.id" :game="game"/>
		</div>
	</div>
</template>

<script>
	import Game from '../components/Game.vue'
	export default {
		name: 'Subcategory',
		components: {
			Game
		},
		props: {
			subcategory: {
				type: Object,
				required: true,
			},
			open: {
				dafault: false
			},
		},
		data() {
			return {
				expanded: false
			}
		},
		computed: {
			games() {
				if (!this.$store.getters.games) {
					return []
				} else {
					return this.$store.getters.games.filter(game => game.subcategoria == this.subcategory.id)
				}
			}
		},
		methods: {
			getGames() {				
				if(this.expanded) {
					this.expanded = false
					this.$forceUpdate()
				} else {
					if (this.games.length == 0) {
						
						this.$store.dispatch('getGames', this.subcategory.id)
						.then(() => {	
							this.expanded = true					
							this.$forceUpdate()
						 })				
					} else {
						this.expanded = true					
						this.$forceUpdate()
					}
					/*
					//ALWAYS CALL THE API TO AVOID PROBS CHANGES ISSUES
					this.$store.dispatch('getGames', this.subcategory.id)
					.then(() => {	
						this.expanded = true					
						this.$forceUpdate()
						})						
					*/
				}
			},
		}
	}
</script>

<style lang="scss" scoped>

</style>