<template>
	<div>		
		<div class="bets__category__subcategory__content__date">
			<h5>{{ game.fecha_cierre | dateParse('YYYY-MM-DD HH:mm:ss') | dateFormat('DD.MM.YYYY - hh:mm a') }}</h5>
		</div>

		<div class="bets__category__subcategory__content__games">
			<table>
				<tr>
					<th>Equipo</th>
					<th>Ganador</th>
					<th>Totales</th>
					<th>Runline</th>					
				</tr>
				<tr v-for="(team, index) in game.teams" :key="team.id">
					<td>{{ team.nombre }}</td>
					
					<td :class="{ selected: bets.includes(getWinner(team.id)) }" @click="toggleBet(getWinner(team.id))">
						{{ achievement(getWinner(team.id).probabilidad) | formatPobability }}
					</td>
					
					<td v-if="getTotal(team.id)" :class="{ selected: bets.includes(getTotal(team.id)) }" @click="toggleBet(getTotal(team.id))">
						{{index == 0 ? '+' : '-'}}{{ getTotal(team.id).cantidad }} {{ achievement(getTotal(team.id).probabilidad) | formatPobability }}
					</td>
					<td v-else disabled></td>
					
					<td v-if="getRunline(team.id)" :class="{ selected: bets.includes(getRunline(team.id)) }" @click="toggleBet(getRunline(team.id))">
						{{ getRunline(team.id).games_probs_signo.signo + getRunline(team.id).cantidad}} {{ achievement(getRunline(team.id).probabilidad) | formatPobability }}
					</td>
					<td v-else disabled></td>
				</tr>
				<tr v-if="tied">
					<td v-if="tied">EMPATE</td>
					<td :class="{ selected: bets.includes(tied) }"  @click="toggleBet(tied)">{{ achievement(tied.probabilidad) | formatPobability }}</td>
				</tr>
				<transition name="fade" enter-active-class="fadeInUp">
					<tr v-if="showExotics" class="exotic-tr">
						<td class="hide-mobile"></td>
						<td class="exotic-td" :colspan="mobileSize ? '4' : '3'">
							<h4>APUESTAS EXÓTICAS</h4>
							<Exotics :exotics="exotics" @toggleBet="toggleBet"/>						
						</td>
					</tr>
				</transition>
			</table>
			<button @click="getExotics" class="button" v-if="game.exoticas_count && !showExotics">Exóticas <span>{{ game.exoticas_count.count }}</span></button>
		</div>
	</div>
</template>

<script>
	import Exotics from '../components/Exotics.vue'
	import {betMixin} from '../mixins/betMixin'
	export default {
		name: 'Game',
		mixins: [betMixin],
		components: {
			Exotics
		},
		props: {
			game: {
				required: true
			}
		},
		data(){
			return {
				showExotics: false,
			}
		},
		computed: {
			games_probs() {
				return this.game.games_probs
			},
			tied() {
				let prob = this.games_probs.find(prob => prob.tipo_probabilidad == 6)
				if (!prob) {
					return null
				}
				return prob
			},
			exotics(){
				return this.$store.getters.exotics(this.game.id)
			},
			bets(){
				return this.$store.getters.bets
			},
			mobileSize() {
				return window.innerWidth < 768
			},
			forbiddenPlay() {
				return this.$store.getters.forbiddenPlay
			},
			betType() {
				return this.$store.getters.betType				
			},
			amount() {
				return this.$store.getters.amount
			}			
		},
		methods: {
			getWinner(teamId) {
				return this.games_probs.find(prob => prob.equipo == teamId && prob.tipo_probabilidad == 1)
			},
			getTotal(teamId) {
				return this.games_probs.find(prob => prob.equipo == teamId && prob.tipo_probabilidad == 4)
			},
			getRunline(teamId) {
				return this.games_probs.find(prob => prob.equipo == teamId && prob.tipo_probabilidad == 3)
			},
			getExotics() {
				this.$store.dispatch('getExotics', this.game.id)
				.then(() => {
					this.showExotics = true
					this.$forceUpdate()
				})					
			},
			toggleBet(payload) {
				if (this.betType && !this.bets.includes(payload)) {

					let betsTypes = this.getTypes(payload)
					
					if(betsTypes) {
						this.isForbidden(payload, betsTypes)
					} else {
						this.$store.dispatch('toggleBet', payload)
						this.$store.commit('setTotal', null)
					}
					
				} else {
					this.$store.dispatch('toggleBet', payload)
					this.$store.commit('setTotal', null)						
				}			
			},
			getTypes(payload) {
				var countBets = 0;
				var betTypes = [payload.tipo_probabilidad];
				var repeated = 0;
				
				for (var i = 0; i < this.bets.length; i++) {
					if(this.bets[i].idgame == payload.idgame) {
						countBets += 1
						betTypes.push(this.bets[i].tipo_probabilidad)
						if(this.bets[i].tipo_probabilidad == payload.tipo_probabilidad){
							repeated += 1
						}
					}
				}				
				
				if(countBets > 0) {
					if(repeated > 0) {
						this.$store.commit('setForbiddenPlay', true)
					}					
					
					return betTypes
				}

				return false
			},
			isForbidden(payload, betsTypes) {
				let rulePayload = {
					game: payload.idgame,
					tipos_apuesta: betsTypes
				}

				if (this.forbiddenPlay) {
					this.$store.commit('setForbiddenPlay', {data: {message: 'Logro duplicado'}})
					
				} else {

					this.$store.dispatch('getRules', rulePayload)
						.then(() => {
							if(!this.forbiddenPlay) {
								this.$store.dispatch('toggleBet', payload)
								this.$store.commit('setTotal', null)	
							}
						})
				}
			},		
		},
	}
</script>

<style lang="scss" scoped>

</style>