<template>
	<div class="playground">
		<Promo/>
		<DialogBox @toggleIcon="toggleBetType">
			<template v-slot:title>
				<TheToggleButton/>
			</template>
			<template v-slot:saldo>
				<h3 class="balance" :class="{big: balance > 99999}" v-if="user && !user.corredor" :title="bonus | formatBalance">
					{{ user.currency_symbol }} {{ balance | formatBalance }} 
					<p v-if="bonus">Bonos: {{ bonus | formatBalance }}</p>
				</h3>
			</template>
			<template v-slot:body>
				<Bets/>
			</template>
		</DialogBox>
		<Modal :show="show" @close="close" :className="['alert', isForbidden() ? 'error' : '', 'playground']">
			<template v-slot:title>
				<h3 v-html="modalData.title"></h3>
			</template>
			<template v-slot:form>
				<p v-html="modalData.message"></p>
				<button v-if="user.corredor == 1 && !isForbidden()" @click="print" class="button">Imprimir</button>
			</template>
		</Modal>
	</div>
</template>

<script>
	import Promo from '../components/Promo.vue'
	import DialogBox from '../components/DialogBox.vue'
	import Modal from '../components/Modal.vue'
	import Bets from '../components/Bets.vue'
	import TheToggleButton from '../components/TheToggleButton.vue'	
	export default {
		name: 'Playground',
		title: 'Jugadas',		
		components: {
			Promo,
			Bets,
			DialogBox,
			Modal,
			TheToggleButton,
		},
		data() {
			return {
				show: false,
			}
		},
		mounted() {
			if (! this.user) {
				this.$router.push({ name: 'Home' })
			}
		},
		computed: {
			user() {
				return this.$store.getters.user
			},
			balance() {
				if(!this.user) {
					return null
				}
				return this.$store.getters.user.saldo
			},
			bonus() {
				if(!this.user) {
					return null
				}
				return this.$store.getters.user.bonus
			},
			betType() {
				return this.$store.getters.betType
			},
			lastBet(){
				return this.$store.getters.lastBet
			},			
			forbiddenPlay(){
				return this.$store.getters.forbiddenPlay
			},
			modalData(){
				let data = {title: 'Resultado<br>de la<br> Apuesta', message: this.lastBet ? this.lastBet.message : ''}
				if (this.isForbidden()) {
					data.title = 'Juagada<br>Prohibida'
					
					if(this.forbiddenPlay) {
						data.message = this.forbiddenPlay.data.message
					}
				}

				return data
			},
		},
		methods: {
			toggleBetType(){
				this.$store.commit('toggleBetType')
			},
			isForbidden() {
				return (this.lastBet && this.lastBet.title == 'Jugada Prohibida') || this.forbiddenPlay
			},
			close() {
				this.show = false

				if (this.isForbidden()) {
					
					this.$store.commit('setForbiddenPlay', null)
				} else {						
					
					this.$store.commit('clearBet')
				}
			},
			print() {
				let payload = this.lastBet.id
				if (!Array.isArray(payload)) {
					this.printTicket(payload)
				} else {
					payload.forEach(id => {
						this.printTicket(id)
					});
				}
			},
			printTicket(payload) {
				this.$store.dispatch('getBet', payload)
					.then(() => {
						window.print()
					})				
			}
		},
		watch: {
			lastBet: {
				handler(val){
					if (val) {
						this.show = true
					}
				},
				deep: true
			},
			forbiddenPlay: {
				handler(val){
					if (val) {
						this.show = true
					}
				},
				deep: true
			},
		}		
	}
</script>

<style lang="scss" scoped>

	h3 {
		
		p {
			font-size: 18px;
			color: $orangeTheme;
			text-align: right;
		}
	}

	@include tabletLandscape {
		
		.playground {
			margin-top: 68%;
		}
	}

	@include mobile {
		h3 {
			
			p {
				font-size: 8px;
			}
		}		
	}
</style>