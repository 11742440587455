<template>
	<table class="exotics">
		<tr>
			<th v-for="(title, index) in table.titles" :key="index">{{title}}</th>
		</tr>
		<tr v-for="(bet, index) in table.name" :key="index">
			<td>{{ bet }}</td>
			<td :class="{ selected: bets.includes(table.prob1[index].bet) }" @click="toggleBet(table.prob1[index].bet)" >{{ table.prob1[index].text }} {{ table.prob1[index].prob | formatPobability }}</td>
			
			<td disabled class="transparent" v-if="table.prob2[index].bet == null"></td>
			<template v-else>
				<td v-if="table.prob2[index]" :class="{ selected: bets.includes(table.prob2[index].bet) }" @click="toggleBet(table.prob2[index].bet)" >{{ table.prob2[index].text }} {{ table.prob2[index].prob | formatPobability }}</td>
			</template>
			
		</tr>
	</table>
</template>

<script>
	import {betMixin} from '../mixins/betMixin'
	export default {
		name: 'Exotics',
		mixins: [betMixin],
		props: {
			exotics: {
				type: Array,
			}
		},
		computed: {
			table() {
				let titles = ['', this.exotics[0].equipo.nombre, this.exotics[1].equipo.nombre]
				let name = []
				let prob1 = []
				let prob2 = []
				for (let index = 0; index < this.exotics.length; index++) {
					const exotic = this.exotics[index];					

					if (! name.includes(exotic.tipo_apuesta.nombre)) {
						name.push(exotic.tipo_apuesta.nombre)						
					}	
					
					if (! this.isOdd(index)) {
						let sign = exotic.games_probs_signo ? exotic.games_probs_signo.signo : ''
						let cant = exotic.cantidad ? exotic.cantidad + ' ' : ''
						cant = exotic.tipo_apuesta.id == 9 ? 'SI' : cant
						prob1.push({ bet: exotic, text: sign + cant, prob: this.achievement(exotic.probabilidad) } )
					}

					if (this.isOdd(index)) {
						let sign = exotic.games_probs_signo ? exotic.games_probs_signo.signo : ''
						let cant = exotic.cantidad ? exotic.cantidad + ' ' : ''
						cant = exotic.tipo_apuesta.id == 9 ? 'NO' : cant
						prob2.push({ bet: exotic, text: sign + cant, prob: this.achievement(exotic.probabilidad) } )
					}
					else if(exotic.tipo_apuesta.probabilidades == 1) {
						prob2.push({ bet: null, text: '', prob: '' } )
					}				

				}
				
				return {titles: titles, name: name, prob1: prob1, prob2: prob2}
			},
			bets(){
				return this.$store.getters.bets
			},			
		},
		methods: {
			isOdd(num) { 
				return num % 2
			},
			toggleBet(payload) {
				this.$emit('toggleBet', payload)
			}			
		}
	}
</script>